<template>
  <Swiper
    v-if="employers.length"
    v-bind="config"
  >
    <SwiperSlide
      v-for="{id, logo, slug} in employers"
      :key="id"
      class="!w-auto"
    >
      <NuxtLink
        v-if="logo"
        :to="`/${slug}`"
      >
        <NuxtPicture
          :src="logo.url"
          :img-attrs="{class: 'h-[50px]'}"
          loading="lazy"
          sizes="250px"
        />
      </NuxtLink>
    </SwiperSlide>
  </Swiper>
</template>

<script setup lang="ts">
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Autoplay} from 'swiper';
import employersQuery from '~/graphql/queries/employers.gql';
import {type Opdrachtgever} from '~/graphql/graphql';

const {result} = useQuery(employersQuery);

const employers = computed<Opdrachtgever[]>(() => result?.value?.employers ?? []);

const SWIPER_BREAK_POINT = 768;

const config = {
  spaceBetween: 40,
  // centeredSlides: true,
  loop: true,
  slidesPerView: 'auto',
  breakpoints: {
    [SWIPER_BREAK_POINT]: {
      spaceBetween: 160,
    },
  },
  init: false,
  modules: [Autoplay],
  autoplay: {duration: 2000, disableOnInteraction: false},
};
</script>
